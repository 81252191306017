<script setup lang="ts">
import type { SbBlokData } from '@storyblok/js';

interface IProps {
    blok: LandingCtaFullWidthImageBlokInterface;
}
const props = defineProps<IProps>();

const hasSubtitle = computed(() => doesRichTextContainText(props.blok?.subtitle));
</script>

<template>
    <page-block-wrapper
        v-editable="blok"
        :disable-bottom-margin="blok.disableBottomMargin">
        <div class="LandingCtaFullWidthImage position-relative overflow-hidden">
            <div
                class="LandingCtaFullWidthImage-image d-flex h-100 position-absolute w-100"
                :class="`LandingCtaFullWidthImage-image--${blok.imageVerticalAlignment}`">
                <nuxt-img
                    :alt="blok.image.alt"
                    class="h-100 w-100"
                    format="webp"
                    loading="lazy"
                    provider="storyblok"
                    sizes="xl:200vw lg:200vw md:200vw sm:200vw xs:200vw"
                    :src="blok.image.filename" />
            </div>
            <div
                class="LandingCtaFullWidthImage-content position-relative pt-300 pb-400 py-sm-600 py-lg-800 text-center text-white">
                <es-container class="py-lg-200 py-xl-800">
                    <es-row class="justify-content-xl-center">
                        <es-col xxl="10">
                            <div class="mb-200 mb-lg-400">
                                <!-- eslint-disable-next-line vuejs-accessibility/heading-has-content -->
                                <h2 class="h1 font-size-lg-800 font-weight-bolder text-white">
                                    <rich-text-component :content="blok.heading" />
                                </h2>
                                <rich-text-component
                                    v-if="hasSubtitle"
                                    :content="blok.subtitle" />
                            </div>
                            <storyblok-component
                                v-for="childBlok in blok.ctas"
                                :key="childBlok._uid"
                                :blok="childBlok as unknown as SbBlokData" />
                        </es-col>
                    </es-row>
                </es-container>
            </div>
        </div>
    </page-block-wrapper>
</template>

<style lang="scss" scoped>
@use '@energysage/es-ds-styles/scss/variables';

.LandingCtaFullWidthImage {
    &-image {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;

        &--center {
            align-items: center;
        }

        &--bottom {
            align-items: flex-end;
        }
    }

    /* semi-transparent dark overlay to make text more readable */
    &::before {
        background-color: variables.$blue-900;
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }

    &-content {
        z-index: 3;
    }
}
</style>
